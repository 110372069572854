/* body{
  --theme-primary-color: rgb(156, 0, 204);
} */
@media print {

  body * {
    visibility: hidden;
    color: black;
  }

  h2{
    break-before: page;
  }

  svg text,tspan{
    fill: black;
  }

  /* svg tspan{
    fill: black;
  } */

  .recharts-wrapper,
    .recharts-surface{
        width: calc(100%)!important;
  }

  .recharts-tooltip-wrapper{
    display: none;
  }


  .printableContainer, .printableContainer * {
    visibility: visible;
    widows: 2;
    orphans: 2;
    /* page-break-inside:avoid; */
  }
  .printableContainer {
    /* position: absolute;
    left: 0;
    top: 0; */
    margin: 10mm;
    max-width: 100%;
  }

  .printableContainer p {
    font-size: 11pt;
    line-height: 14pt;
    text-align: justify;
  }

}

/* blockquote {
  margin-left: 2rem;
  border-left: 0.2rem solid #fff2;
}
*/
blockquote p {
  /* padding-left: 1rem; */
 font-style: italic;
} 

li {
  padding-bottom: 6px;
}

p {
  text-align: justify;
}

h1,
h2,
h3 {
  color: grey;
}




    